import React from "react"
import { graphql } from "gatsby"
import Layout from "./Layout"
import Seo from "./Seo"
import PostList from "./PostList"

const capitalize = s => {
  return s[0].toUpperCase() + s.slice(1)
}

export default function TagsLayout({ pageContext, data }) {
  const { tag } = pageContext
  const displayTag = capitalize(tag)
  const posts = data.allMdx.edges.map(edge => {
    return edge.node
  })
  // TODO: pull tag out on query
  const filtered = posts.filter(post => {
    if (post.frontmatter.tags && post.frontmatter.tags.includes(tag)) {
      return true
    }
    return false
  })

  return (
    <Layout>
      <Seo
        title={`${displayTag} posts`}
        description={`Posts tagged with ${displayTag}`}
      />
      <h1>{displayTag} tagged posts</h1>
      <PostList posts={filtered} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMdx {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            excerpt
            image
            tags
          }
          fields {
            slug
            date
          }
        }
      }
    }
  }
`

